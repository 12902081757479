import './App.css';
import { Box, CssBaseline } from '@mui/material';
import RouterApp from './components/Routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { DialogConfirmationProvider } from './components/DialogConfirmation';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import { BackendFetch } from '@tolgee/react';

moment.locale('pt-br');
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
    },
  },
});

const nameSpaces = ['Common', 'Financial'];
const locales = ['en', 'pt-BR'];

const tolgee = Tolgee().use(BackendFetch()).init({
  language: 'pt-BR',
  defaultNs: 'Common',
  defaultLanguage: 'pt-BR',
  availableLanguages: locales,
  ns: nameSpaces,
});

function App() {
  return (
    <TolgeeProvider tolgee={tolgee}>
      <DialogConfirmationProvider>
        <SnackbarProvider maxSnack={3}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <RouterApp />
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />
          </QueryClientProvider>
        </SnackbarProvider>
      </DialogConfirmationProvider>
    </TolgeeProvider>
  );
}

export default App;
