import { Button, Divider, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getValidationRuleRequiredField } from '../../utils/validation-field/rules-validation';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PropertyPaymentSettings } from '../../services/property.service';
import { useSnackbarMessages } from '../../hooks/useSnackbarMessages';
import {
  getPaymentSettingsOptions,
  paymentSettingsMutationOptions,
} from '../../queries/property-query-options';
import { createBillsMutationOption } from '../../queries/billing-query-options';
import { useNotifications } from '@toolpad/core';
import { useDialogConfirmation } from '../../components/DialogConfirmation';
import moment from 'moment';

type ManualBillingTabProps = {
  propertyId: number | undefined;
};

type ManualBillingFields = {
  ReferenceMonth: number;
  ReferenceYear: number;
};

export const ManualBillingTab = ({ propertyId }: ManualBillingTabProps) => {
  const { errorMessage, recordCreatedWithSuccess, recordUpdatedWithSuccess } =
    useSnackbarMessages();

  const { control, handleSubmit } = useForm<ManualBillingFields>({
    defaultValues: {
      ReferenceMonth: moment(Date.now()).get('M'),
      ReferenceYear: moment(Date.now()).get('y'),
    },
  });
  const createBillsMudation = useMutation(createBillsMutationOption);
  const onSubmit: SubmitHandler<ManualBillingFields> = (data) => {
    showMessage({
      title: 'Tem certeza que deseja gerar cobranças?',
      message:
        'Isso ira gerar uma cobrança para cada Associado no periodo informado',
      type: 'warning',
      onConfirm: () => {
        if (propertyId && data) {
          createBillsMudation.mutate({
            propertyId,
            referenceMonth: data.ReferenceMonth,
            referenceYear: data.ReferenceYear,
          });
        }
      },
    });
  };

  const { showMessage } = useDialogConfirmation();
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography gutterBottom>Cobrança Manual</Typography>
          </Grid>
          <Grid>
            <Controller
              name="ReferenceMonth"
              control={control}
              rules={{
                required: true,
                min: 1,
                max: 12,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Mes"
                  variant="outlined"
                  required
                  type="text"
                  {...getValidationRuleRequiredField(fieldState)}
                />
              )}
            />
          </Grid>
          <Grid>
            <Controller
              name="ReferenceYear"
              control={control}
              rules={{
                required: true,
                min: 2024,
                max: 2025,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Ano"
                  variant="outlined"
                  required
                  type="text"
                  {...getValidationRuleRequiredField(fieldState)}
                />
              )}
            />
          </Grid>
          <Button type="submit" variant="outlined" color="warning">
            Criar Cobrança Manual
          </Button>
        </Grid>
      </form>
    </>
  );
};
