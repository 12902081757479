import * as React from 'react';
import { DataGrid, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PageHeader from '../../components/PageHeader';
import ButtonsActionsZoomFab from '../../components/ButtonsActionsZoomFab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Property } from '../../services/property.service';
import { InputSearch } from '../../components/input-search/input-search';
import { PageContainer } from '@toolpad/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  propertyMutationOptions,
  propertyQueryGetAllOptions,
} from '../../queries/property-query-options';
import { useDialogConfirmation } from '../../components/DialogConfirmation';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export function PropertyPage() {
  const navigate = useNavigate();
  const { showMessage } = useDialogConfirmation();
  const { data, refetch } = useQuery(propertyQueryGetAllOptions());
  const { enqueueSnackbar } = useSnackbar();
  const mutationDelete = useMutation({
    ...propertyMutationOptions,
    onSuccess: () => {
      enqueueSnackbar('Property deleted successfully', { variant: 'success' });
      refetch();
    },
  });

  const columns: GridColDef<Property[][number]>[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      getActions: ({ id }) => [
        <GridActionsCellItem
          key={id}
          icon={<EditIcon />}
          label="Edit 2"
          color="inherit"
          onClick={() => navigate(`/property/${id}`)}
        />,
        <GridActionsCellItem
          key={id}
          icon={<DeleteIcon />}
          label="Delete"
          color="inherit"
          onClick={() => {
            showMessage({
              type: 'warning',
              title: 'Voce tem certeza que deseja excluir essa Propriedade?',
              message: 'Essa ação não pode ser revertidade',
              confirmText: 'Sim',
              onConfirm: () => mutationDelete.mutate(Number(id)),
            });
          }}
        />,
      ],
    },
    { field: 'Id', headerName: 'ID', width: 90 },
    { field: 'Name', headerName: 'Nome', editable: false, width: 150 },
    { field: 'Address', headerName: 'Endereço', editable: false, width: 150 },
    { field: 'City', headerName: 'Cidade', editable: false, width: 150 },
    { field: 'State', headerName: 'Estado', editable: false },
    { field: 'PostalCode', headerName: 'Cep', editable: false },
    {
      field: 'PropertyType',
      headerName: 'Tipo de Propriedade',
      editable: false,
      width: 150,
      renderCell: (item) =>
        item.value === 1
          ? 'Residencial'
          : item.value === 2
            ? 'Comercial'
            : item.value === 3
              ? 'Industrial'
              : 'Rural',
    },
  ];

  return (
    <Box>
      <PageContainer
        maxWidth={false}
        breadCrumbs={[
          { path: '/home', title: 'Home' },
          { path: '/properties', title: 'Propriedades' },
        ]}
      >
        <PageHeader>
          <Grid container>
            <InputSearch placeHolder="Search Properties" />
          </Grid>
        </PageHeader>

        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexGrow: 1,
          }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.Id!}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>

        <ButtonsActionsZoomFab
          type="add"
          onClick={() => {
            navigate(`/property/new`);
          }}
        />
      </PageContainer>
    </Box>
  );
}
