import {
  Button,
  Box,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Modal } from '../../components/Modal';
import { Property, PropertyTypeEnum } from '../../services/property.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  propertyCreateMutationOptions,
  propertyUpdateMutationOptions,
  propertyQueryGetByIdOptions,
} from '../../queries/property-query-options';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getValidationRuleRequiredField } from '../../utils/validation-field/rules-validation';
import { useSnackbarMessages } from '../../hooks/useSnackbarMessages';
import { PropsWithChildren } from 'react';
import { UnitList } from './unit/unit-list';
import { PageContainer } from '@toolpad/core';
import { useNavigate, useParams } from 'react-router-dom';

export function PropertyViewPage() {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { errorMessage, recordCreatedWithSuccess, recordUpdatedWithSuccess } =
    useSnackbarMessages();
  const goBack = () => {
    // onCloseModal();
    navigate('/property');
  };
  console.log('params.id', params.id);

  const { data: property } = useQuery({
    ...propertyQueryGetByIdOptions(params.id),
    enabled: params.id?.toString() !== 'new',
  });

  const mutationAdd = useMutation({
    ...propertyCreateMutationOptions,
    onSuccess: () => {
      recordCreatedWithSuccess();
    },
    onError: (error) => {
      errorMessage(error);
    },
  });

  const mutationUpdate = useMutation({
    ...propertyUpdateMutationOptions,
    onSuccess: () => {
      recordUpdatedWithSuccess();
    },
    onError: (error) => {
      errorMessage(error);
    },
  });

  const onSubmit: SubmitHandler<Property> = (data) => {
    if (data?.Id) {
      mutationUpdate.mutate({
        id: data?.Id,
        property: data,
      });
    } else {
      mutationAdd.mutate({ ...data });
    }
  };

  const { control, handleSubmit, setValue } = useForm<Property>({
    values: {
      ...property,
    },
    defaultValues: {
      Name: '',
      Address: '',
      City: '',
      State: '',
      PostalCode: '',
      PropertyType: PropertyTypeEnum.Residential,
      PropertyManagerId: undefined,
    },
  });

  const BoxContainerInput = ({ children }: PropsWithChildren) => {
    return (
      <Box
        sx={{
          '& > :not(style)': {
            mr: 0.8,
            mt: 2,
            maxWidth: '100%',
          },
        }}
      >
        {children}
      </Box>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageContainer
        maxWidth={false}
        breadCrumbs={[
          { path: '/home', title: 'Home' },
          { path: '/property', title: 'Properties' },
          {
            path: `/property/${params.id}`,
            title: `Visualizar`,
          },
        ]}
      >
        <Box>
          <Box sx={{ mb: 2 }}>
            <BoxContainerInput>
              <Typography gutterBottom variant="h5">
                Informações da Propriedade
              </Typography>
              <Grid container spacing={2}>
                <Grid size={4}>
                  <Controller
                    name="Name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        {...field}
                        label="Nome da Propriedade"
                        variant="outlined"
                        {...getValidationRuleRequiredField(fieldState)}
                      />
                    )}
                  />
                </Grid>
                <Grid size={4}>
                  <Controller
                    name="Address"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        {...field}
                        label="Endereço"
                        variant="outlined"
                        {...getValidationRuleRequiredField(fieldState)}
                      />
                    )}
                  />
                </Grid>
                <Grid size={4}>
                  <Controller
                    name="PostalCode"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        {...field}
                        label="CEP"
                        variant="outlined"
                        {...getValidationRuleRequiredField(fieldState)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid size={3}>
                  <Controller
                    name="City"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        {...field}
                        label="Cidade"
                        variant="outlined"
                        {...getValidationRuleRequiredField(fieldState)}
                      />
                    )}
                  />
                </Grid>
                <Grid size={3}>
                  <Controller
                    name="State"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        {...field}
                        label="Estado"
                        variant="outlined"
                        {...getValidationRuleRequiredField(fieldState)}
                      />
                    )}
                  />
                </Grid>
                <Grid size={3}>
                  <FormControl fullWidth>
                    <InputLabel id="property-type">
                      Tipo de Propriedade
                    </InputLabel>
                    <Controller
                      name="PropertyType"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select {...field} label="Tipo de Propriedade">
                          <MenuItem value={PropertyTypeEnum.Residential}>
                            Residencial
                          </MenuItem>
                          <MenuItem value={PropertyTypeEnum.Commercial}>
                            Comercial
                          </MenuItem>
                          <MenuItem value={PropertyTypeEnum.Industrial}>
                            Industrial
                          </MenuItem>
                          <MenuItem value={PropertyTypeEnum.Rural}>
                            Rural
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </BoxContainerInput>
            <BoxContainerInput>
              <Button color="primary" type="submit" variant="contained">
                Salvar
              </Button>
              <Button color="inherit" onClick={goBack} variant="outlined">
                Voltar
              </Button>
            </BoxContainerInput>
          </Box>
          <Divider />
          <UnitList />
        </Box>
      </PageContainer>
    </form>
  );
}
