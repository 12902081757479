import { queryOptions } from '@tanstack/react-query';
import { Tenant, TenantService } from '../services/tenant.service';

export const tenantGetAllQueryOptions = (searchValue?: string) =>
  queryOptions({
    queryFn: () => TenantService.getAll(searchValue).then((resp) => resp.data),
    queryKey: ['tenant', searchValue],
  });

export const tenantMutationOptions = {
  mutationFn: (tenantId: number) => TenantService.delete(tenantId),
};

export const tenantCreateMudationOptions = {
  mutationFn: (tenant: Tenant) => TenantService.add(tenant),
};
export const tenantUpdateMudationOptions = {
  mutationFn: ({ id, tenant }: { id: number; tenant: Tenant }) =>
    TenantService.update(id, tenant),
};
