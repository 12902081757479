import api from './api';

export type Tenant = {
  Id?: number;
  Name?: string;
  PhoneNumber?: string;
  CpfCnpj?: string;
  Email?: string;
  BusinessType?: BusinessTypeEnum;
  TenantAddress?: TenantAddress;
};

export type TenantAddress = {
  AddressLine1?: string;
  AddressLine2?: string;
  City?: string;
  State?: string;
  PostalCode?: string;
  Neighborhood?: string;
  Number?: string;
};

export enum BusinessTypeEnum {
  Person = 1,
  Business = 2,
}

export const TenantService = {
  getAll(search?: string) {
    return api().get<Tenant[]>(`api/tenant`, {
      params: { value: search },
    });
  },
  delete(tenantId: number) {
    return api().delete(`api/tenant/${tenantId}`);
  },
  add(tenant: Tenant) {
    return api().post(`api/tenant`, tenant);
  },
  update(id: number, tenant: Tenant) {
    return api().put(`api/tenant/${id}`, tenant);
  },
};
