import { Button, Divider, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getValidationRuleRequiredField } from '../../utils/validation-field/rules-validation';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PropertyPaymentSettings } from '../../services/property.service';
import { useSnackbarMessages } from '../../hooks/useSnackbarMessages';
import {
  getPaymentSettingsOptions,
  paymentSettingsMutationOptions,
} from '../../queries/property-query-options';
import { createBillsMutationOption } from '../../queries/billing-query-options';
import { useNotifications } from '@toolpad/core';
import { useDialogConfirmation } from '../../components/DialogConfirmation';

type SettingsTabProps = {
  propertyId: number | undefined;
};

export const SettingsTab = ({ propertyId }: SettingsTabProps) => {
  const { errorMessage, recordCreatedWithSuccess, recordUpdatedWithSuccess } =
    useSnackbarMessages();

  const mudationAddOrUpdate = useMutation({
    ...paymentSettingsMutationOptions,
    onSuccess: () => {
      recordCreatedWithSuccess();
    },
    onError: (error) => {
      errorMessage(error);
    },
  });
  const { data } = useQuery(getPaymentSettingsOptions(propertyId || 0));
  const { control, handleSubmit, watch, setValue, trigger, getValues } =
    useForm<PropertyPaymentSettings>({
      values: data,
      defaultValues: {
        PropertyId: propertyId,
      },
    });
  const onSubmit: SubmitHandler<PropertyPaymentSettings> = (data) => {
    if (propertyId && data) {
      mudationAddOrUpdate.mutate({
        propertyId,
        paymentSettings: data,
      });
    }
  };
  const createBillsMudation = useMutation(createBillsMutationOption);
  const { showMessage } = useDialogConfirmation();
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <Typography gutterBottom>Configuração de Cobrança</Typography>
          </Grid>
          <Grid>
            <Controller
              name="MonthlyFee"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Valor Mensalidade"
                  variant="outlined"
                  required
                  type="text"
                  {...getValidationRuleRequiredField(fieldState)}
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid size={12}>
            <Typography gutterBottom>
              Configuração para processamento Automatico
            </Typography>
          </Grid>

          <Grid>
            <Controller
              name="ProcessingDay"
              control={control}
              rules={{
                required: true,
                min: 1,
                max: 28,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Dia de Processamento"
                  variant="outlined"
                  required
                  type="text"
                  {...getValidationRuleRequiredField(fieldState)}
                />
              )}
            />
          </Grid>
          <Grid>
            <Controller
              name="DueDay"
              control={control}
              rules={{
                required: true,
                validate: {
                  smallerThanProcessingDay: (value) => {
                    const processingDay = watch('ProcessingDay');
                    return (processingDay || 0) < (value || 0);
                  },
                },
                min: 1,
                max: 28,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Dia de Vencimento"
                  variant="outlined"
                  required
                  type="text"
                  {...getValidationRuleRequiredField(fieldState)}
                  helperText={
                    fieldState.error?.type === 'smallerThanProcessingDay'
                      ? 'Dia de vencimento deve ser maior que o dia de processamento'
                      : undefined
                  }
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Button variant="outlined" type="submit">
              Salvar Configurações
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
