import api from './api';

export type Property = {
  Id?: number;
  Name?: string;
  Address?: string;
  City?: string;
  State?: string;
  PostalCode?: string;
  PropertyType?: PropertyTypeEnum;
  PropertyManagerId?: number;
  PropertyManager?: PropertyManager;
};

export type PropertyManager = {
  Id?: number;
  Name?: string;
  ContactInfo?: string;
};

export enum PropertyTypeEnum {
  Residential = 1,
  Commercial = 2,
  Industrial = 3,
  Rural = 4,
}

export type PropertyPaymentSettings = {
  PropertyId?: number | undefined;
  Id?: number;
  MonthlyFee?: number;
  DueDay?: number;
  ProcessingDay?: number;
};

export const PropertyService = {
  getAll(search?: string) {
    return api().get<Property[]>(`api/property`, {
      params: { value: search },
    });
  },
  getById(id: number | string | undefined) {
    return api().get<Property>(`api/property/${id}`);
  },
  delete(propertyId: number) {
    return api().delete(`api/property/${propertyId}`);
  },
  add(property: Property) {
    return api().post(`api/property`, property);
  },
  update(id: number, property: Property) {
    return api().put(`api/property/${id}`, property);
  },
  getPaymentSettings(id: number) {
    return api().get(`api/property/${id}/PaymentSettings`);
  },
  updatePaymentSettings(
    id: number,
    propertyPaymentSettings: PropertyPaymentSettings,
  ) {
    return api().post(
      `api/property/${id}/PaymentSettings`,
      propertyPaymentSettings,
    );
  },
};
