import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { createContext, useCallback, useState } from 'react';

export type DialogStateProps = {
  isOpen?: boolean;
} & ShowMessageProps;

export type ShowMessageProps = {
  type: 'warning' | 'success' | 'info';
  title: string;
  message?: string;
  confirmText?: string;
  onConfirm?: () => void;
  cancelText?: string;
  onCancel?: () => void;
};

export type DialogActionProps = {
  onConfirm?: () => void;
  onCancel?: () => void;
  showMessage: (props: ShowMessageProps) => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Create the context
const DialogConfirmationContext = createContext<DialogActionProps | undefined>(
  undefined,
);

export const useDialogConfirmation = () => {
  const context = React.useContext(DialogConfirmationContext);

  if (!context) {
    throw new Error(
      'useDialogConfirmation must be used within a DialogConfirmationProvider',
    );
  }

  return context;
};

export const DialogConfirmation = ({
  onCancel,
  onConfirm,
  isOpen,
  message,
  cancelText,
  confirmText,
  title,
  type,
}: DialogStateProps & ShowMessageProps) => {
  return (
    <React.Fragment>
      <Dialog
        open={isOpen || false}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCancel}
        aria-describedby="alert-dialog-slide-description"
      >
        {!!title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{cancelText || 'Cancelar'}</Button>
          <Button
            color={type === 'warning' ? 'error' : 'info'}
            onClick={onConfirm}
          >
            {confirmText || 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

// Provider component
export const DialogConfirmationProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [dialogState, setDialogState] = useState<DialogStateProps | undefined>(
    undefined,
  );

  const hideConfirmation = useCallback(() => {
    setDialogState(undefined);
  }, []);

  const confirm = React.useCallback(() => {
    dialogState?.onConfirm?.();
    hideConfirmation();
  }, [dialogState, hideConfirmation]);

  const cancel = React.useCallback(() => {
    dialogState?.onCancel?.();
    hideConfirmation();
  }, [dialogState, hideConfirmation]);

  const showMessage = React.useCallback(
    (props: ShowMessageProps) => {
      const {
        title,
        type,
        cancelText,
        confirmText,
        message,
        onCancel,
        onConfirm,
      } = props;
      setDialogState({
        isOpen: true,
        message,
        title,
        cancelText,
        confirmText,
        onConfirm,
        onCancel,
        type,
      });
    },
    [dialogState, hideConfirmation],
  );

  return (
    <DialogConfirmationContext.Provider
      value={{
        showMessage,
      }}
    >
      <DialogConfirmation
        isOpen={dialogState?.isOpen}
        message={dialogState?.message}
        onCancel={cancel}
        onConfirm={confirm}
        title={dialogState?.title || ''}
        type={dialogState?.type || 'info'}
        cancelText={dialogState?.cancelText}
        confirmText={dialogState?.confirmText}
      />
      {children}
    </DialogConfirmationContext.Provider>
  );
};
