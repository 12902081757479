import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  BillingStatus,
  BillingStatusArray,
  BillingStatusColor,
  getBillingStatusColor,
} from '../../../services/billing.service';
import { useTranslate } from '@tolgee/react';
type StatusFilterToggleProps = {
  onChange?: (value: number[]) => void;
};

export function StatusFilterToggle({ onChange }: StatusFilterToggleProps) {
  const [status, setStatus] = React.useState<number[]>([]);
  const { t } = useTranslate('Financial');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    inputValue: number[],
  ) => {
    // const indexRemove = status?.findIndex((e) => e === inputValue);
    // const newStatus = Array.from<number>(status);
    // if (indexRemove && indexRemove >= 0) {
    //   newStatus?.splice(indexRemove, 1);
    // } else {
    //   newStatus.push(inputValue);
    // }
    setStatus(inputValue);
    onChange?.(inputValue);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={status}
      onChange={handleChange}
      aria-label="Situação Pagamento"
      size="large"
    >
      {BillingStatusArray.map((billingStatus) => (
        <ToggleButton
          color={getBillingStatusColor(billingStatus.Id)}
          value={billingStatus.Id}
          key={billingStatus.Id}
        >
          {t(billingStatus.Name)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
