import * as React from 'react';
import { DataGrid, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, createTheme, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PageHeader from '../../components/PageHeader';
import ButtonsActionsZoomFab from '../../components/ButtonsActionsZoomFab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Tenant } from '../../services/tenant.service';
import { TenantModal } from './tenant-modal';
import { InputSearch } from '../../components/input-search/input-search';
import { AppProvider, PageContainer } from '@toolpad/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  tenantMutationOptions,
  tenantGetAllQueryOptions,
} from '../../queries/tenant-query-options';
import { useDialogConfirmation } from '../../components/DialogConfirmation';
import { useSnackbar } from 'notistack';

export function TenantPage() {
  const { showMessage } = useDialogConfirmation();
  const [dataSubmit, setDataSubmit] = React.useState<Tenant | undefined>();
  const [open, setOpen] = React.useState(false);
  const { data, refetch } = useQuery(tenantGetAllQueryOptions());
  const { enqueueSnackbar } = useSnackbar();
  const mudationDelete = useMutation({
    ...tenantMutationOptions,
    onSuccess: () => {
      enqueueSnackbar('Registro excluido com sucesso', { variant: 'success' });
      refetch();
    },
  });

  const handleEdit = (id: number | string) => {
    const clienteSistema = data?.find(
      (clienteSistema) => clienteSistema.Id === id,
    );
    if (clienteSistema) {
      setDataSubmit(clienteSistema);
      handleOnOpenModal();
    }
  };
  const columns: GridColDef<Tenant[][number]>[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      // width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            color="inherit"
            onClick={() => handleEdit(id)}
          />,
          <GridActionsCellItem
            key={id}
            icon={<DeleteIcon />}
            label="Delete"
            className="textPrimary"
            color="inherit"
            onClick={() => {
              showMessage({
                type: 'warning',
                title: 'Deseja realmente excluir esse Registro',
                message:
                  'Ao excluir esse registro voce não conseguira recuperar',
                confirmText: 'Sim',
                onConfirm: () => mudationDelete.mutate(Number(id)),
              });
            }}
          />,
        ];
      },
    },
    { field: 'Id', headerName: 'ID', width: 90 },
    {
      field: 'Name',
      headerName: 'Nome',
      // width: 150,
      editable: false,
      // valueGetter: (value, row) => row.Name,
    },
    {
      field: 'CpfCnpj',
      headerName: 'Cpf/Cnpj',
      // width: 150,
      editable: false,
    },
    {
      field: 'Email',
      headerName: 'Email',
      // width: 250,
      editable: false,
    },
    {
      field: 'BusinessType',
      headerName: 'Tipo Pessoa',
      editable: false,
      renderCell: (item) => (item.value === 1 ? 'Física' : 'Juridica'),
      // minWidth: 120,
    },
  ];

  const handleOnCloseModal = () => {
    setOpen(false);
    refetch();
  };

  const handleOnOpenModal = () => {
    setOpen(true);
  };

  return (
    <>
      <Box>
        <PageContainer
          maxWidth={false}
          breadCrumbs={[
            { path: '/home', title: 'Home' },
            { path: '/tenants', title: 'Associados' },
          ]}
        >
          {open && (
            <TenantModal
              open={open}
              onCloseModal={handleOnCloseModal}
              onOpenModal={handleOnOpenModal}
              tenant={dataSubmit}
            />
          )}

          <PageHeader>
            <Grid container>
              <InputSearch placeHolder="Consulta Clientes" />
            </Grid>
          </PageHeader>

          <Box
            sx={{
              width: '100%', // Full width of the viewport
              height: '100%', // Full height of the viewport
              // overflow: 'hidden', // Avoid scrollbars
              // backgroundColor: 'blue', // Change to your desired color
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <DataGrid
              rows={data}
              columns={columns}
              getRowId={(row) => row.Id!}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>

          <ButtonsActionsZoomFab
            type="add"
            onClick={() => {
              handleOnOpenModal();
              setDataSubmit(undefined);
            }}
          />
        </PageContainer>
      </Box>
    </>
  );
}
