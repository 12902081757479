import Paper from '@mui/material/Paper';
import { PageContainer } from '@toolpad/core';
import { useTheme } from '@mui/material/styles';
import { AppProvider } from '@toolpad/core/AppProvider';

const NAVIGATION = [
  { segment: '', title: 'Home' },
  { segment: 'orders', title: 'Orders' },
];

export default function Home() {
  const theme = useTheme();
  return (
    <AppProvider navigation={NAVIGATION} theme={theme}>
      <Paper sx={{ width: '100%', height: '100%' }}>
        {/* preview-start */}
        <PageContainer maxWidth={false}></PageContainer>

        {/* preview-end */}
      </Paper>
    </AppProvider>
  );
}
