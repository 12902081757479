import { ChipOwnProps, ToggleButtonProps } from '@mui/material';
import api from './api';
import { Tenant } from './tenant.service';

export enum BillingStatus {
  Created = 0,
  Pending = 1,
  Paid = 2,
  Overdue = 3,
  Cancelled = 4,
}

export type BillingStatusDescription = keyof typeof BillingStatus;

export const BillingStatusArray = Object.entries(BillingStatus)
  .filter(([key, value]) => isNaN(Number(key)))
  .map(([key, value]) => ({ Name: key, Id: Number(value) }));

export function getBillingStatusColor(status: BillingStatus) {
  const color = BillingStatusColor[status];
  return color;
}
export const BillingStatusColor: Record<
  BillingStatus,
  'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
> = {
  '0': 'info',
  '1': 'primary',
  '2': 'success',
  '3': 'warning',
  '4': 'error',
};

export type Bill = {
  Id: number; // Unique identifier for the bill
  TenantId: string; // ID of the associate the bill is generated for
  Tenant: Tenant;
  AmountDue: number; // Total amount due
  AmountPaid: number; // Amount already paid
  DueDate: Date; // Due date for the bill payment
  IssueDate: Date; // Date the bill was issued
  Status: BillingStatus; // Current status of the bill
  Description?: string; // Optional description of the bill's purpose
  LineItems: BillLineItem[]; // List of detailed charges for the bill
  PaymentHistory: Payment[]; // Record of payments applied to this bill
  CreatedBy: string; // User or system who generated the bill
  CreatedDate: Date; // Date when the bill was created
  LastUpdatedBy?: string; // User who last updated the bill
  LastUpdatedDate?: Date; // Date of the last update
};

type BillLineItem = {
  id: number; // Unique identifier for the line item
  description: string; // Description of the specific charge
  quantity: number; // Quantity or units for the line item
  unitPrice: number; // Price per unit
  total: number; // Total cost for this line item (quantity * unitPrice)
};

type Payment = {
  id: number; // Unique identifier for the payment
  billId: string; // ID of the bill this payment applies to
  amount: number; // Amount paid
  paymentDate: Date; // Date when the payment was made
  method: PaymentMethod; // Payment method (e.g., 'Credit Card', 'Bank Transfer')
  referenceNumber?: string; // Optional reference number for tracking
};

type PaymentMethod = 'Credit Card' | 'Bank Transfer' | 'Cash' | 'Check';

export const BillingService = {
  getBills(
    propertyId?: number,
    startDate?: string,
    endDate?: string,
    status?: number[],
  ) {
    const seachParams = {
      startDate,
      endDate,
      status,
      propertyId,
    };
    const searchParamString = Object.entries(seachParams)
      .map(([key, value]) => value && `${key}=${value}`)
      .filter(Boolean)
      .join('&');
    return api().get<Bill[]>(
      `api/Billing/GetBillsByFilters?${searchParamString}`,
    );
  },
  createBillings(
    propertyId: number,
    referenceYear: number,
    referenceMonth: number,
  ) {
    const seachParams = {
      propertyId,
      referenceYear,
      referenceMonth,
    };
    const params = Object.entries(seachParams)
      .map(([key, value]) => value && `${key}=${value}`)
      .filter(Boolean)
      .join('&');
    return api().post(`api/Billing/CreatePropertyTenantBills?${params}`);
  },
  cancelBill(billId?: number) {
    return api().put(`api/Billing/CancelBill/${billId}`);
  },
};
