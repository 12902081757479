import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
// import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
// import DirectionsIcon from "@mui/icons-material/Directions";

type InputSearchProps = {
  placeHolder: string;
  handleSubmit?: (event: React.FormEvent<HTMLFormElement> | undefined) => void;
};

export function InputSearch({ placeHolder, handleSubmit }: InputSearchProps) {
  const onSubmit = (event: React.FormEvent<HTMLFormElement> | undefined) => {
    event?.preventDefault();
    handleSubmit?.(event);
  };
  return (
    <Paper
      component="form"
      onSubmit={onSubmit}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeHolder}
        inputProps={{ 'aria-label': placeHolder }}
        name="searchInput"
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
    </Paper>
  );
}
