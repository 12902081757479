import { UserAuthenticated } from './../../services/auth.service';

const KEY_LOCAL_STORAGE = 'auth';
export const setAuthStorage = (user?: UserAuthenticated) => {
  localStorage.setItem(KEY_LOCAL_STORAGE, JSON.stringify(user));
};

export const getAuthStorage = (): UserAuthenticated | undefined => {
  const authSessionStorage = localStorage.getItem(KEY_LOCAL_STORAGE);
  if (!authSessionStorage) return;
  return JSON.parse(authSessionStorage || '');
};

export const removeAuthStorage = () => {
  localStorage.removeItem(KEY_LOCAL_STORAGE);
};
