import api from './api';
import { Property } from './auth.service';
import { Tenant } from './tenant.service';

export type Unit = {
  Id?: number;
  UnitNumber?: string;
  PropertyId?: number;
  Description?: string;
  CurrentTenantId?: number;
  Property?: Property; // Adjust according to Property data type if needed
  CurrentTenant?: Tenant; // Adjust according to Tenant data type if needed
};

export const UnitService = {
  GetAllByPropertyId(propertyId: number | string | undefined) {
    return api().get<Unit[]>(`api/unit/GetAllByPropertyId/${propertyId}`);
  },
  delete(unitId: number) {
    return api().delete(`api/unit/${unitId}`);
  },
  add(unit: Unit) {
    return api().post(`api/unit`, unit);
  },
  update(id: number, unit: Unit) {
    return api().put(`api/unit/${id}`, unit);
  },
};
