import axios from 'axios';

export type ViaCEPResponse = {
  cep: string;
  logradouro: string;
  complemento: string;
  unidade: string;
  bairro: string;
  localidade: string;
  uf: string;
  estado: string;
  regiao: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
};

export const ViaCEPService = {
  get(postalCode?: string) {
    return axios.get<ViaCEPResponse>(
      `https://viacep.com.br/ws/${postalCode}/json/`,
    );
  },
};
