import axios, { AxiosInstance } from 'axios';
import { getAuthStorage } from '../utils/auth-local-storage';

const baseURL = process.env.REACT_APP_URL_API_BASE;
const client = axios.create({
  baseURL: baseURL,
  timeout: 15000,
});

export const setHeaderToken = (token: string) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeHeaderToken = () => {
  delete client.defaults.headers.common.Authorization;
};

export const request = (): AxiosInstance => {
  client.defaults.headers.common.Authorization = `Bearer ${getAuthStorage()?.Token}`;

  client.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const tokenString = localStorage.getItem('auth') || '';
        if (!tokenString) {
          location.href = '/login';
          return Promise.reject(error);
        }
        const auth = JSON.parse(tokenString);
        if (auth?.Token) {
          try {
            const response = await axios.post(
              `${baseURL}/api/auth/refreshToken`,
              {
                Token: auth.Token,
              },
            );
            // don't use axious instance that already configured for refresh token api call
            const newAccessToken = response.data;
            localStorage.setItem('auth', newAccessToken); //set new access token
            originalRequest.headers.Authorization = `Bearer ${newAccessToken.Token}`;
            return axios(originalRequest); //recall Api with new token
          } catch (error) {
            localStorage.removeItem('auth');
            location.href = '/login';
            // Handle token refresh failure
            // mostly logout the user and re-authenticate by login again
          }
        }
      }
      return Promise.reject(error);
    },
  );

  return client;
};

export default request;
