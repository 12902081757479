import React, { useEffect, useState } from 'react';
import { Fab, SxProps, useTheme, Zoom } from '@mui/material';
import { green } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';

export type ButtonsActionsZoomFabProps = {
  type: 'add' | 'edit' | 'expand' | 'collapse';
  onClick: () => void;
};
export type FabProps = {
  color?: 'primary' | 'secondary' | 'inherit' | 'default' | undefined;
  label?: string;
  sx?: SxProps;
  icon?: React.ReactElement;
};
const ButtonsActionsZoomFab: React.FC<ButtonsActionsZoomFabProps> = ({
  type,
  onClick,
}) => {
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const [fab, setFab] = useState<FabProps | undefined>(undefined);

  const getFab = React.useCallback(() => {
    const fabStyle = {
      position: 'absolute',
      bottom: 16,
      right: 16,
    };

    const fabGreenStyle = {
      color: 'common.white',
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[600],
      },
    };

    switch (type) {
      case 'add':
        return {
          color: 'primary',
          sx: fabStyle,
          icon: <AddIcon />,
          label: 'Add',
        } as FabProps;
      case 'edit':
        return {
          color: 'secondary',
          sx: fabStyle,
          icon: <EditIcon />,
          label: 'Edit',
        } as FabProps;
      case 'expand':
        return {
          color: 'inherit',
          sx: { ...fabStyle, ...fabGreenStyle },
          icon: <UpIcon />,
          label: 'Expand',
        } as FabProps;
    }
  }, [type]);

  useEffect(() => {
    return setFab(getFab());
  }, [getFab]);

  return (
    <div>
      {fab ? (
        <Zoom
          key={fab.color}
          in={true}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${transitionDuration.exit}ms`,
          }}
          unmountOnExit
        >
          <Fab
            sx={fab.sx}
            aria-label={fab.label}
            color={fab.color}
            onClick={onClick}
          >
            {fab.icon}
          </Fab>
        </Zoom>
      ) : null}
    </div>
  );
};

export default ButtonsActionsZoomFab;
