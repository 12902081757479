import { queryOptions } from '@tanstack/react-query';
import { BillingService } from '../services/billing.service';

export const billsQueryOptions = (
  propertyId?: number,
  startDate?: string,
  endDate?: string,
  status?: number[],
) =>
  queryOptions({
    queryFn: () =>
      BillingService.getBills(propertyId, startDate, endDate, status).then(
        (resp) => resp.data,
      ),
    staleTime: 1000,
    queryKey: ['billings', startDate, endDate, status],
  });

export const createBillsMutationOption = {
  mutationFn: ({
    propertyId,
    referenceMonth,
    referenceYear,
  }: {
    propertyId: number;
    referenceYear: number;
    referenceMonth: number;
  }) =>
    BillingService.createBillings(propertyId, referenceYear, referenceMonth),
};
export const cancelBillMutationOption = {
  mutationFn: (propertyId: number) => BillingService.cancelBill(propertyId),
};
