import { ControllerFieldState } from 'react-hook-form';

export function getValidationRuleRequiredField(
  fieldState: ControllerFieldState,
) {
  let message = '';
  if (
    fieldState.invalid &&
    fieldState.error?.type === 'required' &&
    !fieldState.error.message
  ) {
    message = 'Campo Requerido';
  }
  if (fieldState.invalid && fieldState.error?.message) {
    message = fieldState.error.message;
  }
  return {
    error: !!fieldState.error || fieldState.invalid,
    helperText: message,
  };
}

export const patternOnlyLettersAndSpace = {
  pattern: {
    value: /^[A-Z a-z]+$/i,
    message: 'Use somente letras',
  },
};
