import { useSnackbar } from 'notistack';

export function useSnackbarMessages() {
  const { enqueueSnackbar } = useSnackbar();
  function recordCreatedWithSuccess() {
    return enqueueSnackbar('Registro criado com sucesso!', {
      variant: 'success',
    });
  }

  function recordUpdatedWithSuccess() {
    return enqueueSnackbar('Registro atualizado com sucesso!', {
      variant: 'success',
    });
  }

  function errorMessage(error: Error) {
    return enqueueSnackbar(`Ops ocorreu um erro: ${error.message}`, {
      variant: 'error',
    });
  }

  return { recordCreatedWithSuccess, recordUpdatedWithSuccess, errorMessage };
}
