import { queryOptions } from '@tanstack/react-query';
import { Unit, UnitService } from '../services/unit.service';

export const unitQueryGetAllByPropertyIdOptions = (
  propertyId: number | string | undefined,
) =>
  queryOptions({
    queryFn: () => UnitService.GetAllByPropertyId(propertyId),
    queryKey: ['unit', propertyId],
    select: (resp) => resp.data,
  });

export const unitMutationOptions = {
  mutationFn: (unitId: number) => UnitService.delete(unitId),
};

export const unitCreateMutationOptions = {
  mutationFn: (unit: Unit) => UnitService.add(unit),
};

export const unitUpdateMutationOptions = {
  mutationFn: ({ id, unit }: { id: number; unit: Unit }) =>
    UnitService.update(id, unit),
};
