import { Login } from '../pages/auth/login';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Home from '../pages/home';
import { TenantPage } from '../pages/tenant/tenant';
import { CompanyManagerSelectionPage } from '../pages/auth/company-manager-selection';
import DashboardLayoutBasic from './DemoLayout';
import { NotFoundPage } from '../pages/not-found';
import { PropertyPage } from '../pages/property/property-page';
import LogoutPage from '../pages/logout-page';
import { PropertyViewPage } from '../pages/property/property-view-page';
import { FinancialPage } from '../pages/financial/financial-page';

export default function RouterApp() {
  const { user } = useAuth();
  function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation();

    if (!user?.IsAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  }

  return (
    <Routes>
      <Route
        path="/company-manager-selection"
        element={
          <RequireAuth>
            <CompanyManagerSelectionPage />
          </RequireAuth>
        }
      />
      <Route
        element={
          <RequireAuth>
            <DashboardLayoutBasic />
          </RequireAuth>
        }
      >
        <Route path="/home" element={<Home />} />
        <Route path="/tenant" element={<TenantPage />} />
        <Route path="/property" element={<PropertyPage />} />
        <Route path="/property/:id" element={<PropertyViewPage />} />
        <Route path="/financial" element={<FinancialPage />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/" element={<Login />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
