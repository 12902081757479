import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/auth.service';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useMutation, useQuery } from '@tanstack/react-query';
import { setHeaderToken } from '../../services/api';
import { propertyManagerQueryOptions } from '../../queries/auth-query-options';
import React from 'react';
import { Card, CardActions, CardContent } from '@mui/material';

const theme = createTheme();

type PropertyManagerCardProps = {
  propertyName?: string;
  propertyManagerId: number;
  handlePropertySelect: (propertyId: number) => void;
};

const PropertyManagerCard = ({
  propertyManagerId,
  propertyName,
  handlePropertySelect,
}: PropertyManagerCardProps) => (
  <React.Fragment>
    <CardContent>
      <Typography variant="h5" component="div">
        {propertyName}
      </Typography>
    </CardContent>
    <CardActions>
      <Button
        size="small"
        onClick={() => handlePropertySelect(propertyManagerId)}
      >
        Selecionar
      </Button>
    </CardActions>
  </React.Fragment>
);

export function CompanyManagerSelectionPage() {
  const navigate = useNavigate();
  const [, setToken] = useLocalStorage('auth', '');
  const { data: properties } = useQuery(propertyManagerQueryOptions());

  const mudationSetPropertyManager = useMutation({
    mutationFn: (propertyId: number) => {
      return AuthService.updateTokenOptionSession(propertyId);
    },
    onSuccess: ({ data }) => {
      if (data.Token) {
        setHeaderToken(data.Token);
        setToken(data);
        navigate('/home');
      }
    },
  });

  const handlePropertySelect = (propertyId: number) => {
    mudationSetPropertyManager.mutate(propertyId);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Selecione a Propriedade
          </Typography>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            {properties?.map((property) => (
              <Box key={property.PropertyId} sx={{ minWidth: 275 }}>
                <Card variant="outlined">
                  <PropertyManagerCard
                    propertyName={property.PropertyManager.Name}
                    propertyManagerId={property.PropertyManagerId}
                    handlePropertySelect={() =>
                      handlePropertySelect(property.PropertyManagerId)
                    }
                  />
                </Card>
              </Box>
            ))}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
