import api from './api';

export type UserLoginModel = {
  Email: string;
  Password: string;
};

export type UserAuthenticated = {
  Token: string;
  Email: string;
  Name: string;
  IsAuthenticated: boolean;
};
export type PropertyManager = {
  Name: string;
  Adress: string;
  City: string;
};
export type PropertyManagerUser = {
  UserId: number;
  PropertyManagerId: number;
  PropertyId: number;
  PropertyManager: PropertyManager;
};
export enum PropertyType {
  'Residencial' = 1,
  'Commercial' = 2,
  'Industrial' = 3,
  'Rural' = 4,
}
export type Property = {
  Name: string;
  Address: string;
  City: string;
  State: string;
  PostalCode: string;
  PropertyType: PropertyType;
  PropertyManagerId: number;
  PropertyManager: PropertyManager;
  Id: number;
  CreatedAt: string;
  UpdatedAt: string;
};

export const AuthService = {
  login(data: UserLoginModel) {
    return api().post<UserAuthenticated>('/api/Auth/Authenticate', {
      ...data,
    });
  },
  updateTokenOptionSession(propertyManagerId: number) {
    return api().put<UserAuthenticated>(
      `/api/Auth/UpdateTokenOptionSession/${propertyManagerId}`,
    );
  },
  getPropertyManager() {
    return api().get<PropertyManagerUser[]>(`/api/Auth/GetPropertyManager`);
  },
};
