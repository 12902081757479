import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const LogoutPage = () => {
  const navigate = useNavigate();

  const { logOut } = useAuth();
  useEffect(() => {
    const logout = async () => {
      logOut();
      setTimeout(() => navigate('/login'), 2000);
    };

    logout();
  }, [navigate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      textAlign="center"
    >
      <CircularProgress />
      <Typography variant="h6" mt={2}>
        Logging out...
      </Typography>
    </Box>
  );
};

export default LogoutPage;
