import { styled } from '@mui/material';
import React from 'react';

const DivHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  padding: theme.spacing(2, 0, 3, 0),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

function PageHeader({ children }: React.PropsWithChildren) {
  return <DivHeader>{children}</DivHeader>;
}

export default PageHeader;
