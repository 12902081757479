import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react';
import { StatusFilterToggle } from './components/status-filter';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

type Filters = {
  startDate?: string;
  endDate?: string;
  status?: number[];
};

type FinancialFiltersProps = {
  onSearch: (filter: Filters | undefined) => void;
};

export const FinancialFilters = ({ onSearch }: FinancialFiltersProps) => {
  const [filters, setFilters] = useState<Filters>();

  const handleChange = (name: string, event: SelectChangeEvent) => {
    setFilters((prev) => ({
      ...prev,
      [event.target.name]: event.target.value as string,
    }));
  };
  return (
    <Grid container spacing={1} pb={1}>
      <Grid size={2}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Inicio"
              onChange={(value) => {
                setFilters((prev) => ({
                  ...prev,
                  startDate: value?.format('YYYY-MM-DD'),
                }));
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid size={2}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Fim"
              onChange={(value) => {
                setFilters((prev) => ({
                  ...prev,
                  endDate: value?.format('YYYY-MM-DD'),
                }));
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid>
        <StatusFilterToggle
          onChange={(value) => {
            setFilters((prev) => ({
              ...prev,
              status: value || 0,
            }));
          }}
        />
      </Grid>
      <Grid size={12}>
        <Button
          size="large"
          variant="outlined"
          onClick={() => onSearch?.(filters)}
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
};
