import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SettingsTab } from './settings-tab';
import { GeneralTab } from './general-tab';
import { ManualBillingTab } from './manual-billing-tab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
type GeneralTabProps = {
  propertyId: number | undefined;
};
export function FinancialTabs({ propertyId }: GeneralTabProps) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Visão Geral" {...a11yProps(0)} />
          <Tab label="Configuração" {...a11yProps(1)} />
          <Tab label="Cobrança Manual" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <GeneralTab propertyId={propertyId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SettingsTab propertyId={propertyId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ManualBillingTab propertyId={propertyId} />
      </CustomTabPanel>
    </Box>
  );
}
