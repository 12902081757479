import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useQueryClient } from '@tanstack/react-query';
import { tenantGetAllQueryOptions } from '../queries/tenant-query-options';
import { Tenant } from '../services/tenant.service';
import { debounce } from '@mui/material';

type AutocompleteTenantProps = {
  onChange: (value: Tenant | null) => void;
  defaultSelected: Tenant | null | undefined;
};
export function AutocompleteTenant({
  onChange,
  defaultSelected,
  ...rest
}: AutocompleteTenantProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Tenant[]>([
    { ...defaultSelected },
  ]);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const loadData = async (v: string) => {
    setLoading(true);
    const data = await queryClient.fetchQuery(tenantGetAllQueryOptions(v));
    setOptions(data);
    setLoading(false);
  };
  const debouncedLoadData = React.useCallback(debounce(loadData, 1000), []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    event.preventDefault();
    setInputValue(value);
    debouncedLoadData(value);
  };

  const handleChange = (event: React.SyntheticEvent, value: Tenant | null) => {
    event.preventDefault();
    onChange(value);
  };

  return (
    <Autocomplete
      sx={{ width: 300 }}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      isOptionEqualToValue={(option, value) => option.Name === value.Name}
      getOptionLabel={(option) => option.Name || ''}
      options={options || []}
      loading={loading}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={(event, value) => handleChange(event, value)}
      {...rest}
      //   filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Associado"
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            },
          }}
        />
      )}
    />
  );
}
