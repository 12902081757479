import * as React from 'react';
import { DataGrid, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PageHeader from '../../components/PageHeader';
import ButtonsActionsZoomFab from '../../components/ButtonsActionsZoomFab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Property } from '../../services/property.service';
import { InputSearch } from '../../components/input-search/input-search';
import { PageContainer } from '@toolpad/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  propertyMutationOptions,
  propertyQueryGetAllOptions,
} from '../../queries/property-query-options';
import { useDialogConfirmation } from '../../components/DialogConfirmation';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { FinancialTabs } from './financial-tabs';
import { AutocompleteProperties } from '../../components/autocomplete-properties';
import PropertySummary from './property-summary';
import { StatusFilterToggle } from './components/status-filter';

export function FinancialPage() {
  const navigate = useNavigate();
  const { showMessage } = useDialogConfirmation();
  const { data, refetch } = useQuery(propertyQueryGetAllOptions());
  const [property, setProperty] = React.useState<Property | undefined | null>();
  const { enqueueSnackbar } = useSnackbar();
  const mutationDelete = useMutation({
    ...propertyMutationOptions,
    onSuccess: () => {
      enqueueSnackbar('Property deleted successfully', { variant: 'success' });
      refetch();
    },
  });

  return (
    <Box>
      <PageContainer
        maxWidth={false}
        breadCrumbs={[
          { path: '/home', title: 'Home' },
          { path: '/financial', title: 'Financeiro' },
        ]}
      >
        <PageHeader>
          <Grid container>
            <AutocompleteProperties onChange={(value) => setProperty(value)} />
          </Grid>
        </PageHeader>
        {property && (
          <>
            <PropertySummary property={property} />
            <FinancialTabs propertyId={property.Id} />
          </>
        )}
      </PageContainer>
    </Box>
  );
}
