import * as React from 'react';
import { DataGrid, GridColDef, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Button, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PageHeader from '../../../components/PageHeader';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Unit } from '../../../services/unit.service';
import { UnitModal } from './unit-modal';
import { InputSearch } from '../../../components/input-search/input-search';
import { AppProvider, PageContainer } from '@toolpad/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  unitMutationOptions,
  unitQueryGetAllByPropertyIdOptions,
} from '../../../queries/unit-query-options';
import { useDialogConfirmation } from '../../../components/DialogConfirmation';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { AutocompleteTenant } from '../../../components/autocomplete-tentant';

export function UnitList() {
  const { showMessage } = useDialogConfirmation();
  const [dataSubmit, setDataSubmit] = React.useState<Unit | undefined>();
  const [open, setOpen] = React.useState(false);
  const params = useParams<{ id: string }>();
  const { data, refetch } = useQuery({
    ...unitQueryGetAllByPropertyIdOptions(params.id),
    enabled: params.id !== 'new',
  });
  const { enqueueSnackbar } = useSnackbar();

  const mutationDelete = useMutation({
    ...unitMutationOptions,
    onSuccess: () => {
      enqueueSnackbar('Unit deleted successfully', { variant: 'success' });
      refetch();
    },
  });

  const handleEdit = (id: number | string) => {
    const selectedUnit = data?.find((unit) => unit.Id === id);
    if (selectedUnit) {
      setDataSubmit(selectedUnit);
      handleOnOpenModal();
    }
  };

  const columns: GridColDef<Unit>[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      getActions: ({ id }) => [
        <GridActionsCellItem
          key={id}
          icon={<EditIcon />}
          label="Edit"
          color="inherit"
          onClick={() => handleEdit(id)}
        />,
        <GridActionsCellItem
          key={id}
          icon={<DeleteIcon />}
          label="Delete"
          color="inherit"
          onClick={() => {
            showMessage({
              type: 'warning',
              title: 'Voce tem certeza que deseja deletar essa Unidade?',
              message: 'Essa ação não poderá ser revertida',
              confirmText: 'Sim',
              cancelText: 'Cancelar',
              onConfirm: () => mutationDelete.mutate(Number(id)),
            });
          }}
        />,
      ],
    },
    { field: 'Id', headerName: 'ID', width: 90 },
    {
      field: 'UnitNumber',
      headerName: 'Numero da Unidade',
      editable: false,
      width: 150,
    },
    {
      field: 'Description',
      headerName: 'Descrição',
      editable: false,
      width: 200,
    },
    {
      field: 'CurrentTenant.Name',
      headerName: 'Proprietario/Associado',
      valueGetter: (_, row) => `${row.CurrentTenant?.Name}`,
      editable: false,
      width: 200,
    },
  ];

  const handleOnCloseModal = () => {
    setOpen(false);
    refetch();
  };

  const handleOnOpenModal = () => {
    setOpen(true);
  };

  return (
    <Grid container spacing={1} rowSpacing={1}>
      {open && (
        <UnitModal
          open={open}
          onCloseModal={handleOnCloseModal}
          onOpenModal={handleOnOpenModal}
          unit={dataSubmit}
        />
      )}

      <PageHeader>
        <InputSearch placeHolder="Pesquisar Unidades" />
      </PageHeader>
      <Grid size={12}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleOnOpenModal();
            setDataSubmit(undefined);
          }}
        >
          Adicionar Unidade
        </Button>
      </Grid>
      <Grid size={12}>
        <DataGrid
          rows={data || []}
          columns={columns}
          getRowId={(row) => row.Id!}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Grid>
    </Grid>
  );
}
