import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import HouseIcon from '@mui/icons-material/House';
import MoneyIcon from '@mui/icons-material/AttachMoneySharp';
import BarChartIcon from '@mui/icons-material/BarChart';
import { Logout as LogoutIcon } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { AppProvider, type Navigation } from '@toolpad/core';
import { Outlet } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
const NAVIGATION: Navigation = [
  {
    kind: 'header',
    title: 'Menu Principal',
  },
  {
    segment: 'home',
    title: 'Dashboard',
    icon: <DashboardIcon />,
  },
  {
    segment: 'tenant',
    title: 'Clientes',
    icon: <PersonIcon />,
  },
  {
    segment: 'property',
    title: 'Associações',
    icon: <HouseIcon />,
  },
  {
    segment: 'financial',
    title: 'Financeiro',
    icon: <MoneyIcon />,
  },
  // {
  //   kind: 'divider',
  // },
  // {
  //   kind: 'header',
  //   title: 'Relatórios',
  // },
  // {
  //   segment: 'reports',
  //   title: 'Relatórios',
  //   icon: <BarChartIcon />,
  //   children: [
  //     {
  //       segment: 'sales',
  //       title: 'Sales',
  //       icon: <DescriptionIcon />,
  //     },
  //   ],
  // },
  {
    kind: 'divider',
  },
  {
    segment: 'logout',
    title: 'Sair',
    icon: <LogoutIcon />,
  },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 300,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function DashboardLayoutBasic() {
  return (
    <AppProvider
      navigation={NAVIGATION}
      theme={demoTheme}
      branding={{
        title: 'SGA',
      }}
      session={{
        user: {
          email: 'carlos.cfcortez@gmail.com',
          name: 'Carlos Cortez',
        },
      }}
    >
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    </AppProvider>
  );
}
