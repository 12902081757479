import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Property, PropertyTypeEnum } from '../../services/property.service';

interface PropertySummaryProps {
  property: Property | undefined | null;
}

const PropertySummary: React.FC<PropertySummaryProps> = ({ property }) => {
  if (!property) {
    return null;
  }

  const {
    Name,
    Address,
    City,
    State,
    PostalCode,
    PropertyType,
    PropertyManager,
  } = property;

  const propertyTypeLabel = PropertyType
    ? PropertyTypeEnum[PropertyType]
    : 'Unknown';

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {Name || 'Unnamed Property'}
        </Typography>

        <Box>
          <Typography variant="body2" color="textSecondary">
            {Address ? `${Address},` : ''} {City}, {State} {PostalCode}
          </Typography>
        </Box>

        <Box>
          <Typography variant="body1">Tipo: {propertyTypeLabel}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PropertySummary;
