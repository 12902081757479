import { queryOptions } from '@tanstack/react-query';
import {
  Property,
  PropertyPaymentSettings,
  PropertyService,
} from '../services/property.service';

export const propertyQueryGetAllOptions = () =>
  queryOptions({
    queryFn: () => PropertyService.getAll('').then((resp) => resp.data),
    queryKey: ['property'],
  });

export const propertyQueryGetByIdOptions = (id: number | string | undefined) =>
  queryOptions({
    queryFn: () => PropertyService.getById(id),
    queryKey: ['property', id],
    select: (resp) => resp?.data,
  });

export const propertyMutationOptions = {
  mutationFn: (propertyId: number) => PropertyService.delete(propertyId),
};

export const propertyCreateMutationOptions = {
  mutationFn: (property: Property) => PropertyService.add(property),
};

export const propertyUpdateMutationOptions = {
  mutationFn: ({ id, property }: { id: number; property: Property }) =>
    PropertyService.update(id, property),
};

//PaymentSettings
export const getPaymentSettingsOptions = (propertyId: number) =>
  queryOptions({
    queryFn: () =>
      PropertyService.getPaymentSettings(propertyId).then((resp) => resp.data),
    queryKey: ['propertyPaymentSettings', propertyId],
  });

export const paymentSettingsMutationOptions = {
  mutationFn: ({
    propertyId,
    paymentSettings,
  }: {
    propertyId: number;
    paymentSettings: PropertyPaymentSettings;
  }) => PropertyService.updatePaymentSettings(propertyId, paymentSettings),
};
