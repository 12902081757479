import {
  Button,
  Box,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Modal } from '../../components/Modal';
import { BusinessTypeEnum, Tenant } from '../../services/tenant.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  tenantCreateMudationOptions,
  tenantUpdateMudationOptions,
} from '../../queries/tenant-query-options';
import { TextMaskCnpj } from '../../components/TextMaskCnpj';
import { TextMaskCpf } from '../../components/TextMaskCpf';
import { useSnackbar } from 'notistack';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  getValidationRuleRequiredField,
  patternOnlyLettersAndSpace,
} from '../../utils/validation-field/rules-validation';
import { useSnackbarMessages } from '../../hooks/useSnackbarMessages';
import { PropsWithChildren } from 'react';
import { viaCepQueryOptions } from '../../queries/viacep-query-options';

interface TenantModalProps {
  onOpenModal: () => void;
  onCloseModal: () => void;
  open: boolean;
  tenant?: Tenant;
}

export function TenantModal({ onCloseModal, open, tenant }: TenantModalProps) {
  const queryClient = useQueryClient();
  const { errorMessage, recordCreatedWithSuccess, recordUpdatedWithSuccess } =
    useSnackbarMessages();
  const handleClose = () => {
    onCloseModal();
  };

  const mudationAdd = useMutation({
    ...tenantCreateMudationOptions,
    onSuccess: () => {
      recordCreatedWithSuccess();
      onCloseModal();
    },
    onError: (error) => {
      errorMessage(error);
    },
  });

  const mudationUpdate = useMutation({
    ...tenantUpdateMudationOptions,
    onSuccess: () => {
      recordUpdatedWithSuccess();
      onCloseModal();
    },
    onError: (error) => {
      errorMessage(error);
    },
  });

  const onSubmit: SubmitHandler<Tenant> = (data) => {
    if (data?.Id) {
      mudationUpdate.mutate({
        id: data?.Id,
        tenant: data,
      });
    } else {
      if (data) {
        mudationAdd.mutate({ ...data });
      }
    }
  };

  const { control, handleSubmit, watch, setValue, trigger } = useForm<Tenant>({
    values: {
      ...tenant,
    },
    defaultValues: {
      Name: '',
      Email: '',
      CpfCnpj: '',
      BusinessType: BusinessTypeEnum.Person,
    },
  });

  const handleSetValuesAddress = async (postalCode?: string) => {
    if (postalCode) {
      const { data } = await queryClient.fetchQuery(
        viaCepQueryOptions(postalCode),
      );
      setValue('TenantAddress.AddressLine1', data.logradouro);
      setValue('TenantAddress.Neighborhood', data.bairro);
      setValue('TenantAddress.City', data.localidade);
      setValue('TenantAddress.State', data.uf);
    }
  };

  const BoxContainerInput = ({ children }: PropsWithChildren) => {
    return (
      <Box
        sx={{
          '& > :not(style)': {
            m: 0.8,
            mt: 2,
            maxWidth: '100%',
          },
        }}
      >
        {children}
      </Box>
    );
  };

  const federalUnities = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  return (
    <Modal
      open={open}
      closeModal={handleClose}
      modalTitle="Cadastro de Associado"
      fullWidth
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <BoxContainerInput>
          <Typography gutterBottom variant="h5">
            Informações Pessoais
          </Typography>
          <Grid container spacing={2}>
            <Grid size={2}>
              <FormControl fullWidth>
                <InputLabel id="business-type">Tipo Pessoa</InputLabel>
                <Controller
                  name="BusinessType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <Select
                      labelId="business-type"
                      {...field}
                      label="Tipo Pessoa"
                    >
                      <MenuItem value={BusinessTypeEnum.Person}>
                        Fisica
                      </MenuItem>
                      <MenuItem value={BusinessTypeEnum.Business}>
                        Juridica
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid size={3}>
              <Controller
                name="CpfCnpj"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    {...field}
                    id="outlined-basic"
                    label={
                      watch('BusinessType') === BusinessTypeEnum.Business
                        ? 'Cnpj'
                        : 'Cpf'
                    }
                    variant="outlined"
                    required
                    type="text"
                    slotProps={{
                      input: {
                        ...field,
                        ...getValidationRuleRequiredField(fieldState),
                        inputComponent: (watch('BusinessType') ===
                        BusinessTypeEnum.Person
                          ? TextMaskCpf
                          : TextMaskCnpj) as never,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid size={3}>
              <Controller
                name="Name"
                control={control}
                rules={{
                  required: true,
                  ...patternOnlyLettersAndSpace,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    {...field}
                    required
                    id="outlined-basic"
                    label="Nome"
                    type="text"
                    variant="outlined"
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </BoxContainerInput>
        <Divider />
        <BoxContainerInput>
          <Grid container spacing={2}>
            <Grid size={8}>
              <Controller
                name="Email"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    {...field}
                    id="outlined-basic"
                    label="Email"
                    required
                    variant="outlined"
                    type="email"
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </BoxContainerInput>
        <Divider />
        <BoxContainerInput>
          <Typography gutterBottom variant="h6">
            Endereço
          </Typography>

          <Grid container spacing={2}>
            <Grid>
              <Controller
                name="TenantAddress.PostalCode"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="CEP"
                    variant="outlined"
                    required
                    type="text"
                    onBlur={() => handleSetValuesAddress(field.value)}
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>
            <Grid size={4}>
              <Controller
                name="TenantAddress.AddressLine1"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Logradouro (Rua/Avenida)"
                    variant="outlined"
                    required
                    type="text"
                    fullWidth
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>
            <Grid size={2}>
              <Controller
                name="TenantAddress.Number"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Numero"
                    variant="outlined"
                    type="text"
                    fullWidth
                    required
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>
            <Grid size={4}>
              <Controller
                name="TenantAddress.AddressLine2"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Complemento"
                    variant="outlined"
                    type="text"
                    fullWidth
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>
            <Grid size={4}>
              <Controller
                name="TenantAddress.Neighborhood"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Bairro"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>

            <Grid size={3}>
              <Controller
                name="TenantAddress.City"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Cidade"
                    variant="outlined"
                    type="text"
                    required
                    fullWidth
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>

            <Grid size={1}>
              <FormControl fullWidth>
                <InputLabel id="federal-unit-label">UF</InputLabel>
                <Controller
                  name="TenantAddress.State"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState }) => (
                    <Select
                      {...field}
                      labelId="federal-unit-label"
                      id="federal-unit"
                      label="UF"
                    >
                      <MenuItem value="">--Selecione a UF--</MenuItem>
                      {federalUnities.map((uf) => (
                        <MenuItem key={uf} value={uf}>
                          {uf}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </BoxContainerInput>

        <DialogActions>
          <Button autoFocus color="primary" type="submit">
            Salvar
          </Button>
          <Button autoFocus color="inherit" onClick={() => onCloseModal()}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Modal>
  );
}
