import { PropsWithChildren, createContext, useState, useContext } from 'react';
import { UserAuthenticated } from '../services/auth.service';
import { getAuthStorage, removeAuthStorage } from '../utils/auth-local-storage';

function getInitialState() {
  return getAuthStorage();
}

function logOut() {
  removeAuthStorage();
}

type AuthContextProps = {
  user?: UserAuthenticated;
  setUser: (user: UserAuthenticated) => void;
  logOut: () => void;
};

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined,
);

export const useAuth = () => {
  const useAuthContext = useContext(AuthContext);

  if (!useAuthContext) {
    throw new Error('useAuth must be used wrapped by AuthContextProvider');
  }

  return useAuthContext;
};

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [authState, setAuthState] = useState(getInitialState());
  function setUserState(user: UserAuthenticated) {
    setAuthState(user);
  }

  return (
    <AuthContext.Provider
      value={{
        user: authState,
        setUser: setUserState,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
