import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogActions } from '@mui/material';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ModalProps = {
  open: boolean;
  closeModal: () => void;
  modalTitle: string;
  actionButtons?: React.ReactElement;
  children: React.ReactElement;
  actionButtonPosition?: 'header' | 'footer';
} & DialogProps;

export function Modal({
  open,
  closeModal,
  modalTitle,
  children,
  actionButtons,
  fullScreen = false,
  actionButtonPosition = 'footer',
  ...rest
}: ModalProps) {
  const handleClose = () => {
    closeModal();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        {...rest}
      >
        <AppBar sx={{ position: 'relative' }} color="default">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {modalTitle}
            </Typography>
            <DialogActions>
              {actionButtonPosition === 'header' && actionButtons}
            </DialogActions>
          </Toolbar>
        </AppBar>
        {children}
        <DialogActions>
          {actionButtonPosition === 'footer' && actionButtons}
        </DialogActions>
      </Dialog>
    </div>
  );
}
