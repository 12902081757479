import {
  Badge,
  Box,
  Button,
  Chip,
  ChipOwnProps,
  ChipPropsColorOverrides,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { Property } from '../../services/auth.service';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/BlockOutlined';
import { useNavigate } from 'react-router-dom';
import { useDialogConfirmation } from '../../components/DialogConfirmation';
import { FinancialFilters } from './financial-filters';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  billsQueryOptions,
  cancelBillMutationOption,
  createBillsMutationOption,
} from '../../queries/billing-query-options';
import {
  Bill,
  BillingStatus,
  BillingStatusArray,
  BillingStatusColor,
  getBillingStatusColor,
} from '../../services/billing.service';
import moment from 'moment';
import { currencyFormat } from '../../utils/currency-format/currency-format';
import { useState } from 'react';
import { useTranslate } from '@tolgee/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type GeneralTabProps = {
  propertyId: number | undefined;
};

export const GeneralTab = ({ propertyId }: GeneralTabProps) => {
  const navigate = useNavigate();
  const { showMessage } = useDialogConfirmation();
  const queryClient = useQueryClient();

  const cancelBillMutation = useMutation(cancelBillMutationOption);
  const [data, setData] = useState<Bill[] | undefined>();
  const { t } = useTranslate('Financial');

  const columns: GridColDef<Bill[][number]>[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      getActions: ({ id }) => [
        <GridActionsCellItem
          key={id}
          icon={<CancelIcon />}
          label="Cancelar"
          color="inherit"
          onClick={() =>
            !isNaN(Number(id)) ? cancelBillMutation.mutate(Number(id)) : null
          }
        />,
      ],
    },
    { field: 'Id', headerName: 'ID', width: 90 },
    {
      field: 'TenantName',
      headerName: 'Cliente/Associado',
      editable: false,
      width: 250,
      valueGetter: (_, row) => row.Tenant.Name,
    },
    {
      field: 'StatusBadge',
      headerName: 'Situação',
      editable: false,
      width: 150,
      renderCell: ({ row: { Status } }) => (
        <Chip
          variant="outlined"
          color={`${getBillingStatusColor(Status)}`}
          label={t(BillingStatus[Status])}
        />
      ),
    },
    {
      field: 'IssueDate',
      headerName: 'Processado em:',
      editable: false,
      width: 150,
      valueGetter: (value) => moment(value).format('L'),
    },
    {
      field: 'DueDate',
      headerName: 'Data de Vencimento',
      editable: false,
      width: 150,
      valueGetter: (value) => moment(value).format('L'),
    },
    {
      field: 'AmountDue',
      headerName: 'Valor Devido',
      editable: false,
      valueGetter: (value) => currencyFormat.format(value),
    },
    {
      field: 'AmountPaid',
      headerName: 'Valor Pago',
      editable: false,
      valueGetter: (value) => currencyFormat.format(value),
    },
  ];

  return (
    <>
      <Typography gutterBottom>
        Relação de Associados Adimplentes e Inadimplentes
      </Typography>
      <FinancialFilters
        onSearch={async (filter) => {
          const response = await queryClient.fetchQuery(
            billsQueryOptions(
              propertyId,
              filter?.startDate,
              filter?.endDate,
              filter?.status,
            ),
          );
          setData(response);
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.Id!}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
};
