import {
  Button,
  Box,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Typography,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Modal } from '../../../components/Modal';
import { Unit } from '../../../services/unit.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  unitCreateMutationOptions,
  unitUpdateMutationOptions,
} from '../../../queries/unit-query-options';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { getValidationRuleRequiredField } from '../../../utils/validation-field/rules-validation';
import { useSnackbarMessages } from '../../../hooks/useSnackbarMessages';
import { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import React from 'react';
import { Tenant } from '../../../services/tenant.service';
import { tenantGetAllQueryOptions } from '../../../queries/tenant-query-options';
import { AutocompleteTenant } from '../../../components/autocomplete-tentant';

interface UnitModalProps {
  onOpenModal: () => void;
  onCloseModal: () => void;
  open: boolean;
  unit?: Unit;
}

export function UnitModal({ onCloseModal, open, unit }: UnitModalProps) {
  const queryClient = useQueryClient();
  const params = useParams();

  const { errorMessage, recordCreatedWithSuccess, recordUpdatedWithSuccess } =
    useSnackbarMessages();

  const handleClose = () => {
    onCloseModal();
  };

  const mutationAdd = useMutation({
    ...unitCreateMutationOptions,
    onSuccess: () => {
      recordCreatedWithSuccess();
      onCloseModal();
    },
    onError: (error) => {
      errorMessage(error);
    },
  });

  const mutationUpdate = useMutation({
    ...unitUpdateMutationOptions,
    onSuccess: () => {
      recordUpdatedWithSuccess();
      onCloseModal();
    },
    onError: (error) => {
      errorMessage(error);
    },
  });

  const onSubmit: SubmitHandler<Unit> = (data) => {
    if (data?.Id) {
      mutationUpdate.mutate({
        id: data?.Id,
        unit: data,
      });
    } else {
      mutationAdd.mutate({ ...data });
    }
  };

  const { control, handleSubmit, setValue } = useForm<Unit>({
    values: {
      ...unit,
      PropertyId: Number(params.id),
    },
    defaultValues: {
      UnitNumber: '',
      Description: '',
    },
  });

  const BoxContainerInput = ({ children }: PropsWithChildren) => (
    <Box
      sx={{
        '& > :not(style)': {
          m: 0.8,
          mt: 2,
          maxWidth: '100%',
        },
      }}
    >
      {children}
    </Box>
  );

  return (
    <Modal
      open={open}
      closeModal={handleClose}
      modalTitle="Cadastro de Unidade"
      fullWidth
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <BoxContainerInput>
          <Typography gutterBottom variant="h5">
            Informações da Unidade
          </Typography>
          <Grid container spacing={2}>
            <Grid size={4}>
              <Controller
                name="UnitNumber"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="Número da Unidade"
                    variant="outlined"
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid>
            {/* <Grid size={4}>
              <Controller
                name="PropertyId"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="ID da Propriedade"
                    variant="outlined"
                    {...getValidationRuleRequiredField(fieldState)}
                  />
                )}
              />
            </Grid> */}
            <Grid size={4}>
              <Controller
                name="Description"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    label="Descrição"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={6}>
              <Controller
                name="CurrentTenantId"
                control={control}
                render={({ field }) => (
                  <AutocompleteTenant
                    onChange={(value) => setValue('CurrentTenantId', value?.Id)}
                    defaultSelected={unit?.CurrentTenant}
                  />
                )}
              />
            </Grid>
          </Grid>
        </BoxContainerInput>
        <Divider />
        <DialogActions>
          <Button autoFocus color="primary" type="submit">
            Salvar
          </Button>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Modal>
  );
}
